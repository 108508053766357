import React, { useState } from "react"
import parse from "html-react-parser"

const ReadMore = ({ firstContent, secondContent, children }) => {
  const [showContent, setShowContent] = useState(false)
  const [viewText, setViewText] = useState("View More")

  const onViewClick = () => {
    setShowContent(!showContent)
    setViewText(showContent ? "View More" : "View Less")
  }

  let shortContent = firstContent.substring(0, 110)

  if (
    secondContent == null ||
    secondContent === ""
  ) {
    return (
      <p key={firstContent.substring(1, 6)}>
        {!showContent ? shortContent + "..." : firstContent }
        {!showContent ? "" : <br/> }
        <p className="read-more-text" onClick={onViewClick}>
          {" " + viewText}
        </p>
      </p>
    )
  }

  secondContent = parse(secondContent)

  return (
    <p key={firstContent.substring(1, 6)}>
      {!showContent ? 
        shortContent + "..."
        : <>
            {firstContent}
            <br />
            <br />
            {secondContent}
          </>
      }
      <p className="read-more-text" onClick={onViewClick}>
        {" " + viewText}
      </p>
    </p>
  )
}

export default ReadMore
