import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
 
const ImageLoad = (props) => {
return (
  
    <LazyLoadImage
      alt={props.alt}
      height={props.height}
      src={props.src} // use normal <img> attributes as props
      effect="blur"
      width={props.width} />
  
)
}
 
export default ImageLoad;
