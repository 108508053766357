/**
 * External dependencies
 */
import React, {useEffect, useState} from "react"
import axios from "axios"
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import parse from 'html-react-parser';
import { Helmet } from "react-helmet";


/**
 * Internal dependencies
 */
import Stars from "../stars/stars"

import "./styles/_index.scss"
const postsPerPage = 7;
let arrayForHoldingPosts = [];

const PropertyReviews = () => {

  const [testimonials, setTestimonials] = useState([])
  const formatter = buildFormatter(frenchStrings)
  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(7);
  useEffect(() => {
      arrayForHoldingPosts = [];
  }, []);


  const strapiconfig = {
      headers: {
          Authorization:
              `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
      },
  }
  const getitems = async url => {
    try {
        const { data } = await axios.get(url,strapiconfig)// could be from env files
        setTestimonials(data)
    } catch (error) {
        // cache it if fail/error;
        console.error(error)
    }
  }
  const loopWithSlice = (start, end) => {
    const slicedPosts = testimonials.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };
  useEffect(() => {
    testimonials && loopWithSlice(0, postsPerPage);
  }, [testimonials]);


  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url);
  }, [])
  return (
    <div className="property-reviews" data-aos="fade-up">
      <Helmet>
  <script type="application/ld+json">{`{
 "@context": "http://schema.org/",
 "@type": "Product",
 "name": "LambornHill",
 "Image": "https://www.lambornhill.com/static/logo-7830a5ce7a19b1450b31def831f04e5f.svg",
 "description": "LambornHill are the locally trusted estate agents &amp; letting agents in Sittingbourne, Isle of Sheppey and Kent.",
 "aggregateRating": {
     "@type": "AggregateRating",
     "ratingValue": "4.5",
     "reviewCount": "69"
 }

}`}</script>
</Helmet>

      <div className="shell">
        <div className="reviews--head">
          <h2>Built on a foundation of happy and repeat customers</h2>
          <p>We’re hardworking, efficient professionals. That’s why we  build lasting relationships with every client we serve.</p>
        </div>
        {postsToShow && postsToShow.map((review, index) => {
        if(review.starRating =="FIVE" && review.comment && review.comment.length > 0){
          return<>
          <div className="property-reviews__inner">
            <Stars count={review.starRating}/>
            <p>
              {parse(review.comment)}
            </p>
            <h5>{review.reviewer.displayName}</h5>
            <small><TimeAgo date={review.createTime} formatter={formatter} /></small>
          </div>
          </>
        }
        })}
         {postsToShow  && postsToShow.length >= next &&
                <a onClick={handleShowMorePosts} class="btn btn--outline-darkblue review--btn">Load More</a>
            }

      </div>
    </div>
  )
}

export default PropertyReviews
