/**
 * External dependencies
 */
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import "./styles/_index.scss"

const PropertyPerson = ({ email, phone, user }) => {
  return (
    <div className="property-person">
      <div className="property-person__image">
        <StaticImage
          src="./images/avatar.jpg"
          width={80}
          height={80}
          alt="avatar"
        />
      </div>
      <div className="property-person__details">
        <h4>{user}</h4>
        <p>
          <Link to="/contact-estate-agents-in-sittingbourne/">{email}</Link>
        </p>
        <div className="property-person__details-phone">
          <a href={`tel:${phone}`}>{phone}</a>
        </div>
      </div>
    </div>
  )
}

export default PropertyPerson
