/**
 * External dependencies
 */
import React from "react";

import "./styles/_index.scss"

const ItemListContent = ({ children }) => (
  <div className="item-list__content">{children}</div>
)

const ItemListIcon = ({ children }) => (
  <div className="item-list__icon">{children}</div>
)

const ItemList = ({ children }) => <div className="item-list">{children}</div>

ItemList.Icon = ItemListIcon
ItemList.Content = ItemListContent

export default ItemList;
