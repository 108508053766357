/**
 * External dependencies
 */
import { useStaticQuery, graphql } from "gatsby"

const usePropertyVideos = () => {
  const {
    allFile: { edges: items },
  } = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          dir: { regex: "/property-videos/images/" }
        }
      ) {
        edges {
          node {
            name
            childrenImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)

  return items
}

export default usePropertyVideos
