/**
 * External dependencies
 */
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Accordion, Col, Row } from "react-bootstrap"

/**
 * Internal dependencies
 */
import "./styles/_index.scss"
import AccordionToggle from "../accordion-toggle/accordion-toggle"
import ImageLoad from "../imageload"
import { AltSiteName } from "../utils"
import parse from 'html-react-parser';

const PropertyPrepare = (props) => (
  <div className="property-prepare" data-aos="fade-up">
    <div className="shell">
      <Row>
        {props.image &&
        <Col lg={6} className="property-prepare__image">
          <ImageLoad src={props.image.url} alt={props.image.alternativeText?props.image.alternativeText:props.title+AltSiteName} />               
        </Col>
        }
        <Col
          lg={{
            span: 5,
            offset: 1,
          }}
        >
          <h4>{props.title}</h4>
          <p>
            {props.description}
          </p>
          <Accordion flush>
          {props && props.list.length > 0 && props.list.map((item, index) => {
            return<>
            <Accordion.Item eventKey={item.id}>
              <AccordionToggle eventKey={item.id}>{item.Accordion_Title}</AccordionToggle>
              <Accordion.Body>
              {parse(item.Accordion_Description)}
              </Accordion.Body>
            </Accordion.Item>
            </>
            })}
          </Accordion>
        </Col>
      </Row>
    </div>
  </div>
)

export default PropertyPrepare
